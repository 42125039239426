import { LocationQueryValue, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const ROUTE_NAME_HOME = 'home'
export const ROUTE_NAME_SEARCH = 'search'
export const ROUTE_NAME_INTEGRATED_HOME = 'ihome'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ]
  },

  {
    path: '/simple-ui-integrated/',
    component: () => import('layouts/IntegratedLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_INTEGRATED_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ]
  },

  {
    path: '/search',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_SEARCH,
        path: '',
        component: () => import('pages/SearchPage.vue'),
        props: (route: RouteLocationNormalized): {q: LocationQueryValue | LocationQueryValue[]} => {
          return { q: route.query.q }
        }
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
